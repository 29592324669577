<template>
  <base-section
    id="affiliates"
    space="36"
  >
    <v-container>
      <base-section-heading title="MEMBERS OF" />
      <v-row>
        <v-col
          v-for="num in 3"
          cols="4"
          :key="num"
        >
            <base-img
              :src="require(`@/assets/affiliate-${num}.png`)"
              color="grey"
              contain
              height="90"
              width="120"
              class="mx-auto"
            />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAffiliates',
  }
</script>
